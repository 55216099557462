<template>
  <div class="help-title">Tech Support</div>
  <div class="about">
    <div class="document">
      <div class="title">
        API Document
        <a href="https://www.apifox.cn/apidoc/project-1723596" target="_blank">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M2.64122 7.9445C2.25069 8.33503 2.25069 8.96819 2.64122 9.35872C3.03174 9.74924 3.66491 9.74924 4.05543 9.35872L6.17675 7.2374C6.56728 6.84687 6.56728 6.21371 6.17675 5.82318L6.88386 5.11608C7.66491 5.89713 7.66491 7.16346 6.88386 7.9445L4.76254 10.0658C3.98149 10.8469 2.71516 10.8469 1.93411 10.0658C1.15306 9.28478 1.15306 8.01845 1.93411 7.2374L3.17155 5.99996L3.87865 6.70707L2.64122 7.9445ZM5.11609 6.88384C4.33504 6.1028 4.33504 4.83647 5.11609 4.05542L7.23741 1.9341C8.01846 1.15305 9.28479 1.15305 10.0658 1.9341C10.8469 2.71515 10.8469 3.98148 10.0658 4.76252L8.8284 5.99996L8.12129 5.29285L9.35873 4.05542C9.74926 3.66489 9.74926 3.03173 9.35873 2.6412C8.96821 2.25068 8.33504 2.25068 7.94452 2.6412L5.8232 4.76252C5.43267 5.15305 5.43267 5.78621 5.8232 6.17674L5.11609 6.88384Z"
              fill="#165dff" />
          </svg>
          Link
        </a>
      </div>
      <div class="link">
        <div>
          For test account, please visit <span>https://www.amparcel.com and register.</span>
        </div>
        <div>
          ADS use OAuth 2 personal access token to access your resource.You need to generate an access token first. It
          will last for one year.If your token is lost or expired, regenerate it.Your should put this in http header
          (replace the token with yours, note that there is a space after
          "Bearer"):
        </div>
      </div>
      <div class="link">
        <span class="num">1.0</span>
        <a-button type="outline" shape="round" @click="generate">Generate Access Token</a-button>
      </div>
    </div>
    <div class="time">The brief quide below will save your time</div>
    <div class="title2">API Guide</div>
    <div class="link2">You don't need to read whole document, coz usually you don't need all APIs.<br />
      Here is what you need:<br />
      1. We suggest you to get POSTMAN to test API.<br />
      2. You definitely need 1.0 above to generate a token. It will DISABLE the one you generated previously.<br />
      3. Most commonly used is 2.1 (Get Label) to get a shipping label and 2.4 (Get Batch Labels) to get a lot of
      labels.<br />
      4. You may need to cancel a label that you don't need it and get refund, that's 2.2 (Cancel Order).<br />
      5. You definitely need to read Appendix.
    </div>
    <div class="title2 note">Notes</div>
    <div class="link2">
      1.Length, Width, Height are required.<br />
      2.Parameter case needs to be consistent with the document.<br />
      3.By default, we use Imperial units.<br />
      4.If our system detect your recipient address is residential address, we will change the service to Home
      Delivery.<br />
      5.When you are ready to move to production, you only need to change ur and token.<br />
      6.The lenath limit of some fields(characters)<br />
      7.Name: 35, Company: 35, Street1|Street2: 35, City: 20, Ponumber: 40, Invoice: 40, Reference: each 40, Phone: 15,
      Phone<br /> &nbsp;&nbsp;&nbsp;Extension: 6, Zip Code: 10
    </div>
    <!-- 填写弹框 -->
    <a-modal v-model:visible="visibleInput" @cancel="handleCancel" :width="520" :render-to-body="false">
      <template #title>
        <span class="modal-title">Generate Access Token</span>
      </template>
      <a-form :model="form" layout="vertical" ref="formRef">
        <a-form-item field="name" label="Program Name" :rules="nameRule" :validate-trigger="['blur']">
          <a-input v-model="form.name" placeholder="Required 2-30 characters" autocomplete="off" />
        </a-form-item>
        <a-form-item field="password" label="Account Password" :validate-trigger="['blur']" :rules="passwordRule">
          <a-input-password v-model="form.password" autocomplete="new-password" allow-clear placeholder="Required" />
        </a-form-item>
      </a-form>
      <template #footer>
        <a-space>
          <a-button @click="confirmReset" shape="round">Reset</a-button>
          <a-button type="primary" shape="round" @click="submitGenerate">Generate</a-button>
        </a-space>
      </template>
    </a-modal>
    <!-- 结果弹框 -->
    <a-modal v-model:visible="visibleResult" @cancel="reslutCancel" :width="520" :render-to-body="false">
      <template #title>
        <span class="modal-title">Your Access Token</span>
      </template>
      <a-textarea :auto-size="true" v-model="result" />
      <template #footer>
        <a-space>
          <a-button type="primary" shape="round" @click="cpoy">Copy</a-button>
        </a-space>
      </template>
    </a-modal>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue'
import { accessToken } from '@/api/help.js'
import { changePwd } from '@/api/account.js'
// 使用loading
import { load } from '@/utils/loading.js'
import { Message, Notification } from '@arco-design/web-vue'
const visibleInput = ref(false)
const visibleResult = ref(false)
const form = reactive({
  name: '',
  password: ''
})
const passwordOk = ref(false)
const result = ref('')
const formRef = ref(null)
const generate = () => {
  visibleInput.value = true
}
const submitGenerate = async () => {
  if (!passwordOk.value) {
    return
  }
  load.show('Submitting...')
  const msg = await accessToken({
    app_name: form.name,
    password: form.password
  })
  if (msg.code === 0) {
    load.hide()
    visibleInput.value = false
    result.value = msg.data.token
    visibleResult.value = true
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
const handleCancel = () => {
  visibleInput.value = false
}
const reslutCancel = () => {
  visibleResult.value = false
}
const confirmReset = () => {
  formRef.value.resetFields()
}
const cpoy = () => {
  const input = document.createElement("input")
  document.body.appendChild(input)
  input.setAttribute("value", result.value)
  input.select()
  if (document.execCommand("copy")) {
    document.execCommand("copy")
    Message.success('Copied.')
  }
  document.body.removeChild(input)
}
const nameRule = [{
  validator: (value, cd) => {
    if (!value) {
      cd(new Error('Required').message)
    } else if (value.length > 30 || value.length <= 2) {
      cd(new Error('2-30 characters').message)
    }
  }
}]
const passwordRule = [{
  validator: async (value, cd) => {
    if (!value) {
      cd(new Error('Required').message)
      passwordOk.value = false
    } else {
      const msg = await changePwd({
        old_password: value
      })
      if (msg.code === 0) {
        passwordOk.value = true
        cd()
      } else {
        passwordOk.value = false
        cd(new Error('Authentication failed.').message)
      }
    }
  }
}]
</script>
<style lang="less" scoped>
.help-title {
  margin: 18px 24px 12px 48px;
  font-weight: 500;
  font-size: 20px;
  color: var(--color-text-1);
}

.about {
  margin: 18px 0 0 60px;

  .title {
    font-weight: 500;
    font-size: 16px;
    height: 48px;
    line-height: 48px;
    padding-left: 16px;
    margin-right: 76px;
    border-bottom: 1px solid var(--color-border-2);

    a {
      text-decoration: none;
      color: rgb(var(--link-6));
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin-left: 20px;
      padding: 1px 4px;

      &:hover {
        background: var(--color-fill-2);
        border-radius: 12px;
      }
    }
  }

  .link {
    color: var(--color-text-3);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding: 16px 76px 0 20px;

    span {
      color: rgb(var(--link-6));
      display: inline-block;
      margin-left: 8px;
    }

    .generate {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: inline-block;
      text-align: center;
      width: 188px;
      height: 32px;
      line-height: 32px;
      border: 1px solid #165DFF;
      margin-left: 12px;
    }

    .num {
      padding-right: 16px;
      color: var(--color-text-1);
    }
  }

  ul li {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(34, 39, 37, 0.6);
  }

  .time {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-1);
    margin-top: 24px;
  }

  .title2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-top: 16px;
    margin-left: 20px;
  }

  .link2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-text-2);
    margin-left: 20px;
  }
}

.note {
  margin-top: 46px !important;
}

// 弹框
.modal-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1D2129;
}

:deep(.arco-form-item-label-col>.arco-form-item-label) {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #4E5969;
}

:deep(.arco-modal-footer) {
  text-align: center;
}

.arco-textarea-wrapper {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #1D2129;
  border-radius: 2px;
}

.arco-textarea-wrapper:focus-within,
.arco-textarea-wrapper.arco-textarea-focus {
  border-color: #13B966;
}
</style>
